<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col min-h-full">
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white p-6 lg:p-12 lg:border lg:rounded-xl lg:my-6">
        <header class="text-center">
          <ExclamationCircleIcon class="p-3 h-12 w-12 mx-auto mb-3 text-yellow-600 bg-yellow-100 rounded-xl" />
          <h2 class="font-bold text-xl leading-9 mb-3">
            Já existe um link ativo para esse CPF
          </h2>
          <p class="text-gray-500">
            Você deve continuar editando o link ativo para continuar. Caso queira criar um novo link para o mesmo CPF, é necessário excluir o link existente.
          </p>
        </header>
        <div class="mt-6">
          <div class="flex flex-col gap-5">
            <Button
              v-if="itsPossibleToGenerateLink"
              @click="goToCreditPaymentLink"
              link
              kind="primary"
            >
              Continuar editando o link ativo
            </Button>
            <Button link @click="goToPaymentLinkMenu">
              Ir para listagem de links
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from '@vue-hero-icons/outline';
import { mapActions, mapState } from 'vuex';
import { Button } from '@/components';

export default {
  name: 'link-already-associated-to-cpf',
  metaInfo: {
    title: 'Novo link de pagamentos',
  },
  components: {
    ExclamationCircleIcon,
    Button,
  },

  methods: {
    ...mapActions('paymentLink', ['resetPaymentLinkCreation']),
    ...mapActions('loading', ['setIsLoading']),
    async goToCreditCheck() {
      this.resetPaymentLinkCreation();
      await this.$router.replace({ name: 'credit-check' });
    },
    async goToPaymentLinkMenu() {
      this.resetPaymentLinkCreation();
      await this.$router.replace({ name: 'payment-link-list' });
    },
    async goToCreditPaymentLink() {
      this.setIsLoading({ isLoading: false, showIcon: 'success', showIconDelay: 1500 });
      await this.$router.replace({
        name: 'create-payment-link',
      });
    },
  },
  computed: {
    ...mapActions('paymentLink', ['dailyTries']),
    ...mapState('paymentLink', ['newPaymentLink']),
    itsPossibleToGenerateLink() {
      return this.dailyTries || this.newPaymentLink.status === 'INCOMPLETE';
    },
  },
};
</script>
<style scoped>
  .centralize-exclamation {
    display: flex;
    justify-content: center;
  }
</style>
